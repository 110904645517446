import React from 'react'
import Layout from '../layouts/mainlayout.js'


export default function index() {
  return (
    <Layout>
      <p>This is a Demo page</p>
    </Layout>
  )
}